import React, { Component } from 'react'
import Prismic from 'prismic-javascript';
import FontAwesome from 'react-fontawesome'
import './styles.scss'

import PageTitle from './../../components/page-title/'
import ApplicationForm from '../../components/application-form/'

class CareerLayout extends Component {
  state = {
    doc: null,
  }

  componentWillMount() {
    const apiEndpoint = 'https://alllcare.prismic.io/api/v2';

    Prismic.api(apiEndpoint).then(api => {
      api.query(
        Prismic.Predicates.at('document.type', 'careers')
      ).then(response => {
        if (response) {
          this.setState({ doc: response.results[0] });
        }
      });
    });
  }

  render() {
    if (this.state.doc) {
      const document = this.state.doc.data
      return (
        <div>
          <PageTitle
            title={document.title[0].text}
            text={document.description}
          />
          <div className='careerContentContainer'>
            <div className='careerInfoContainer'>
              <div className='careerInfo'>
                <h2>ALLCARE Career Opportunities</h2>
                {document.career_positions.map((career, i) => {
                  return <p key={i}><FontAwesome name='check-circle' className='bullet' />{career.career_position}</p>
                })}
              </div>
              <img className='careerImg' src={document.imge.url} alt="Career"/>
            </div>
            <div className='careerFormContainer'>
              <ApplicationForm
                positions={this.state.careers}
              />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="spinner">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
    );
  }
}

export default CareerLayout;
