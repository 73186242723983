import React, { Component } from 'react'
import Prismic from 'prismic-javascript';
import './styles.scss'

class ApplicationForm extends Component {
  state = {
    doc: null,
    name: "",
    email: "",
    phone: "",
    positions: "",
  };

  componentWillMount() {
    const apiEndpoint = 'https://alllcare.prismic.io/api/v2';

    Prismic.api(apiEndpoint).then(api => {
      api.query(
        Prismic.Predicates.at('document.type', 'careers')
      ).then(response => {
        if (response) {
          this.setState({ doc: response.results[0] });
        }
      });
    });
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    const {name, email, phone, positions } = this.state;
    return (
      <div>
        <form className='formContainer' name='application' action="https://formspree.io/info@alllcare.com" method="POST">
        <input type="hidden" name="form-name" value="application" />
          <div className='formSection'>
            <h2>Apply Now</h2>
          </div>
          <div className='formSection'>
            <div className='sectionTitle'>
              <h2>Tell us who you are</h2>
            </div>
            <div className='sectionInputs'>
              <div className='input input2'>
                <label htmlFor='name' >Full Name</label>
                <input type='text' id='name' name='name' value={name} onChange={this.handleChange} required/>
              </div>
              <div className='input input2'>
                <label htmlFor='email' >Email Address</label>
                <input type='email' id='email' name='email' value={email} onChange={this.handleChange} required/>
              </div>
              <div className='input input2'>
                <label htmlFor='phone' >Phone Number</label>
                <input type='tel' id='phone' name='phone' value={phone} onChange={this.handleChange} required/>
              </div>
            </div>
          </div>
          <div className='formSection'>
            <div className='sectionTitle'>
              <h2>Job Positions</h2>
            </div>
            <div className='sectionInputs'>
              <div className='input'>
                <label htmlFor='positions'>Positions</label>
                <select className='selectDropdown' id='positions' name="positions" value={positions} onChange={this.handleChange} required>
                  <option value="none" defaultValue></option>
                  {this.state.doc ? (
                    this.state.doc.data.career_positions.map((career, i) => {
                      return (
                        <option key={i} value={`${career.career_position}`} onChange={this.handleChange}>{career.career_position}</option>
                      )
                    })
                  ) : (
                      null
                  )}
                </select>
              </div>
              <div style={{ marginBottom: 25 }} >
                <label htmlFor='resume'style={{ lineHeight: 2 }}>Upload your resumes</label>
                <input id='resume' name="resume" type="file"/>
              </div>
            </div>
          </div>
          <div className='formBtnContainer' >
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    );
  }
}

export default ApplicationForm;
