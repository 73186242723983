import React from 'react'
import Content from './../containers/content/'
import CareerLayout from '../containers/careers-layout/'
import Layout from "../components/layouts"

const IndexPage = () => (
  <Layout>
    <Content>
      <CareerLayout/>
    </Content>
  </Layout>
)

export default IndexPage
